import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import JsxParser from 'react-jsx-parser';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';

import items from '../exportItems.json';
import defaultOptions from '../../analysis/defaultOptionValues.json'
import { ExportModal } from '../../ExportModal/ExportModal';
import WeightWizard from '../../WeightWizard/WeightWizard';
import { OptionsDialog } from '../../OptionsDialog/OptionsDialog';
import DroppableColumn from '../../../DroppableColumn/DroppableColumn';
import usePrevious from '../../../../../../../shared/customHooks/usePrevious';
import { ErrorMessage } from '../../../../../../../shared/ErrorMessage/ErrorMessage';
import { ExportActionItem } from '../../../../shared/ExportActionItem/ExportActionItem';
import returnActionsData from '../../../../shared/helpers/returnActionsData/returnActionsData';
import { InProgressOverlay } from '../../../../../../../shared/InProgressOverlay/InProgressOverlay';
import returnInitialOptions from '../../../../shared/helpers/returnInitialOptions/returnInitialOptions';
import { returnUpdatedAnalysisBody } from '../../../../shared/helpers/returnUpdatedAnalysisBody/returnUpdatedAnalysisBody';
import setToplineClearQuestionItems from '../../../../shared/helpers/setToplineClearQuestionItems/setToplineClearQuestionItems';
import returnUpdatedAnalysisActionItems from '../../../../shared/helpers/returnUpdatedAnalysisActionItems/returnUpdatedAnalysisActionItems';
import returnToplineOptionsInitialValues from '../../../../shared/helpers/returnToplineOptionsInitialValues/returnToplineOptionsInitialValues';
import { returnMultiSelectionData } from '../../../../shared/helpers/returnMultiSelectionData/returnMultiSelectionData';
import { getUpdatedAnalysisOptions } from '../../../../shared/helpers/getUpdatedAnalysisOptions/getUpdatedAnalysisOptions';
import { returnAnalysisActionsItems } from '../../../../shared/helpers/returnAnalysisActionsItems/returnAnalysisActionsItems';
import { ZoomButtons } from '../../../../shared/ZoomButtons/ZoomButtons';
import { LanguageSwitchButton } from '../../../../shared/helpers/languageSwitchButton/LanguageSwitchButton';
import { fetchPost as fetchCopyXML, fetchPostJson as updateToplineData } from '../../../../../../../../services/services';
import { returnBrowser } from '../../../../shared/helpers/returnBrowser/returnBrowser';
import { CopyXmlModal } from '../../CopyXmlModal/CopyXmlModal';
import { Icon } from '../../../../../../../shared/Icon/Icon';
import { ExpandResultModal } from '../../ExpandResultModal/ExpandResultModal';
import { DropdownButton } from '../../../../../../../shared/DropdownButton/DropdownButton';

export const ToplineTabContent = ({ onAutoSaveHandler, userData, datasetId, datasetName, datasetType, optionsData, weightValues, expandedOptions, showOptionsModal, handleOptionsDialogClose, openOptionsDialog, user, token, rangeItems, analysisFunctionalities, onApplyWeightSet }) => {
  const { theData, languages, defaultLanguage, editingLanguage, showFullscreenResult } = useSelector(theState => (theState.setInitialDataReducer))
  const { state, undoQuestionOptions, redoQuestionOptions } = useSelector((theState) => (theState.toplineStateReducer));
  // const { state, undoQuestionOptions, redoQuestionOptions } = useSelector(theState => ({
  //   ...theState.toplineStateReducer,
  //   ...theState.surveyInitialDataReducer
  // })); TODO check if surveyInitialDataReducer is used
  const userSettings = useSelector(theState => theState.userSettingsReducer);
  // const { selectedSession } = useSelector(theState => ({ ...theState.breadcrumbStateReducer }))
  const zoomLevel = userSettings.zoomLevels.topLineZoom;
  const [combineData, setCombineData] = useState([])
  const [showExportModal, setShowExportModal] = useState({ show: false, action: null, type: null, extension: null })
  const [showWeightWizard, setShowWeightWizard] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [didMount, setDidMount] = useState(true)
  const [checkedRows, setCheckedRows] = useState([])
  const [showCopyXmlModal, setShowCopyXmlModal] = useState({ show: false, xmlCode: '' })

  const history = useHistory()
  const dispatch = useDispatch();
  const params = useParams()

  const prevUpdateTable = state ? usePrevious(state.updateTable) : null

  const updatedItems = returnUpdatedAnalysisActionItems(analysisFunctionalities, items, datasetType) // export dropdown
  const actionItems = returnAnalysisActionsItems(undoQuestionOptions, redoQuestionOptions, true, datasetType, !state.firstColumn.length) // actions dropdown
  const allColumnsDisabled = state.firstColumn.filter(e => !e.disabled).length === 0 ? true : false

  // const sessionDropdownItems = returnSessionDropdownItems(selectedSession)
  const projectId = params.name

  useEffect(() => {
    dispatch({ type: "TOPLINE_SET_UPDATE_TABLE_STATE", payload: { status: true } })
  }, [dispatch])

  useEffect(() => {
    const checkedRowsFiltered = state.firstColumn.filter((item) => item.selected).map(el => el.id)
    if (didMount) {
      const checkedRowsNums = checkedRowsFiltered.map(e => ({ source: 'firstColumn', id: e }))
      dispatch({ type: 'TOPLINE_UPDATE_CHECKED_NUM', payload: checkedRowsNums })
      setDidMount(false)
    }
    setCheckedRows(checkedRowsFiltered)
  }, [state.firstColumn, didMount, dispatch])

  useEffect(() => {
    if (state.updateTable && state.updateTable !== prevUpdateTable) {
      let dataUrl = `an/projects/${projectId}/analysis/${datasetId}`
      if (datasetType === 'surveys') {
        dataUrl = `an/projects/${projectId}/analysis/surveys/${datasetId}`
      }
      const body = returnUpdatedAnalysisBody(params.analysisType, 'topline', state.newQuestionOptions, state.firstColumn, '', '', editingLanguage)
      updateToplineData(dataUrl, token, body)
        .then(res => {
          if (res?.error) {
            setErrorMessage(res.error)
            dispatch({ type: 'TOPLINE_SET_TABLE_STATE', payload: { value: null } })
          } else {
            const updatedResult = res.result.replace(/{/g, "&#123;").replace(/}/g, "&#125;");
            dispatch({ type: 'TOPLINE_SET_TABLE_STATE', payload: { value: updatedResult } })
            setErrorMessage(null)
          }
        })
      onAutoSaveHandler(state)
    }
  }, [state.newQuestionOptions, state.firstColumn, state.updateTable, prevUpdateTable, dispatch, datasetId, token, onAutoSaveHandler, projectId, datasetType, editingLanguage, params.analysisType])

  const onSelectQuestion = (e, id) => {
    dispatch({ type: 'TOPLINE_SELECT_QUESTION', payload: { id: id, theData: theData } })
  }

  const onCheckQuestion = (e, id, source, itemId, shouldDefineRange) => {
    const updatedRowsData = returnMultiSelectionData(state.firstColumn, checkedRows, shouldDefineRange, itemId, (data) => setCheckedRows(data), 'selected')
    dispatch({ type: 'TOPLINE_UPDATE_ROWS', payload: updatedRowsData })
    setDidMount(true)
  }

  const clearQuestions = (props) => {
    if (props.item.action === "TOPLINE_CLEAR_QUESTION_OPTIONS") {
      const toplineValues = returnToplineOptionsInitialValues(optionsData.data, user, optionsData.groups)
      const initialOptions = returnInitialOptions(defaultOptions[0].topline, user, optionsData.groups, optionsData.data)
      dispatch({ type: props.item.action, payload: { values: toplineValues, options: initialOptions } })

    } else if (props.item.action === "TOPLINE_CLEAR_QUESTIONS") {
      const toplineValues = returnToplineOptionsInitialValues(optionsData.data, user, optionsData.groups)
      const initialOptions = returnInitialOptions(defaultOptions[0].topline, user, optionsData.groups, optionsData.data)
      dispatch({ type: props.item.action, payload: { values: toplineValues, options: initialOptions } })
    } else if (state.firstColumn.length > 0) {
      dispatch({ type: props.item.action })
      setErrorMessage(null)
    }
  }

  const handleOptionsUpdate = (newValues, values, data, option) => {
    const updatedQuestionOptions = Object.assign({}, state.newQuestionOptions, newValues)
    dispatch({ type: 'TOPLINE_SET_QUESTION_OPTIONS', payload: { newValues: updatedQuestionOptions, values: values } })

    if (option === "FilterCode") {
      dispatch({ type: 'TOPLINE_SET_FILTER_DATA', payload: { data: data } })
    }
    else if (option === "UniverseCode") {
      dispatch({ type: 'TOPLINE_SET_UNIVERSE_DATA', payload: { data: data } })
    }

    const updatedOptionsWithInitialValue = getUpdatedAnalysisOptions(state, values)
    dispatch({ type: 'UPDATE_TOPLINE_UNDO_QUESTION_OPTIONS', payload: [...undoQuestionOptions, updatedOptionsWithInitialValue] })

    handleOptionsDialogClose()
  }

  const setCombineFilterData = (id) => {
    if (id === "FilterCode") {
      const filterDataCopy = [...state.filterData]
      setCombineData(filterDataCopy)
    } else {
      const universeDataCopy = [...state.universeData]
      setCombineData(universeDataCopy)
    }
  }

  const onOptionsClick = () => {
    const filterData = [...state.filterData];
    const universeData = [...state.universeData];
    const questionOptions = { ...state.questionOptions };
    const newQuestionOptions = { ...state.newQuestionOptions };

    dispatch({
      type: "SET_TOPLINE_INITIAL_OPTIONS_DATA",
      payload: {
        initialFilterData: filterData,
        initialUniverseData: universeData,
        initialQuestionOptions: questionOptions,
        initialNewQuestionOptions: newQuestionOptions,
      }
    });

    openOptionsDialog();
  }

  const clearQuestionItems = setToplineClearQuestionItems()

  const exportFunc = (props) => {
    if (state.firstColumn && state.firstColumn.length > 0 && allColumnsDisabled === false) {
      setShowExportModal({ show: true, action: props.item.action, type: props.item.actionName, extension: props.item.extension })
    }
  }

  const actionItemRender = (item) => {
    return (
      <ExportActionItem
        user={user}
        props={{ item }}
        state={state}
        datasetId={datasetId}
        history={history}
        token={token}
        disabled={allColumnsDisabled}
        showExportModal={exportFunc}
        tab='topline' />
    )
  }

  const onAddToReport = () => {
    let dataUrl = `an/projects/${projectId}/analysis/${datasetId}`
    if (datasetType === 'surveys') {
      dataUrl = `an/projects/${projectId}/analysis/surveys/${datasetId}`
    }
    const reportQuestionsData = state.firstColumn.filter((item) => item.selected)
    const reportData = reportQuestionsData.map(item => {
      return {
        body: returnUpdatedAnalysisBody(params.analysisType, 'topline', state.newQuestionOptions, state.firstColumn, [], item.id),
        dataUrl: dataUrl,
        icon: "fa fa-window-maximize fa-sm"
      }
    })
    dispatch({ type: 'SET_REPORT_COLUMN_DATA', payload: { data: reportData } });
    dispatch({ type: 'SET_REPORT_QUESTIONS_DATA', payload: { data: reportQuestionsData.map(item => { return { ...item, id: uuid(), type: "topline", active: false, selected: false } }) } })
    dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The topline result has been added to report successfully.' } });
  }

  const onActionHandler = (action) => {
    const updatedToplineState = JSON.parse(JSON.stringify(state))
    const updatedToplineQuestions = JSON.parse(JSON.stringify(updatedToplineState.firstColumn))

    if (action === 'enable') {
      updatedToplineQuestions.filter((item) => item.selected).forEach((el) => {
        el.disabled = false
      })
      updatedToplineQuestions.forEach((item) => {
        item.selected = false
      })

      dispatch({ type: 'TOPLINE_ENABLE_SELECTED_QUESTIONS', payload: updatedToplineQuestions })
    } else if (action === 'disable') {
      updatedToplineQuestions.filter((item) => item.selected).forEach((el) => {
        el.disabled = true
      })
      updatedToplineQuestions.forEach((item) => {
        item.selected = false
      })
      dispatch({ type: 'TOPLINE_DISABLE_SELECTED_QUESTIONS', payload: updatedToplineQuestions })
    } else if (action === 'weight') {
      setShowWeightWizard(true)
    } else if (action === 'select rows') {
      const rows = state.firstColumn
      rows.map(e => e.selected = true)
      dispatch({ type: 'TOPLINE_UPDATE_ROWS', payload: rows })
    } else if (action === 'deselect rows') {
      const rows = state.firstColumn
      rows.map(e => e.selected = false)
      dispatch({ type: 'TOPLINE_UPDATE_ROWS', payload: rows })
    } else if (action === 'undo') {
      const updatedUndoQuestionOptions = [...undoQuestionOptions]
      const itemToAdd = undoQuestionOptions[undoQuestionOptions.length - 1]
      updatedUndoQuestionOptions.pop()
      dispatch({
        type: 'TOPLINE_UNDO_LAST_CHANGE', payload: {
          questionOptions: { ...state.questionOptions, ...itemToAdd },
          newQuestionOptions: { ...state.newQuestionOptions, ...itemToAdd },
          undoQuestionOptions: updatedUndoQuestionOptions,
          redoQuestionOptions: [...redoQuestionOptions, state.questionOptions]
        }
      })
    } else if (action === 'redo') {
      const updatedRedoQuestionOptions = [...redoQuestionOptions]
      const itemToAdd = redoQuestionOptions[redoQuestionOptions.length - 1]
      updatedRedoQuestionOptions.pop()
      dispatch({
        type: 'TOPLINE_REDO_LAST_CHANGE', payload: {
          questionOptions: { ...state.questionOptions, ...itemToAdd },
          newQuestionOptions: { ...state.newQuestionOptions, ...itemToAdd },
          redoQuestionOptions: updatedRedoQuestionOptions,
          undoQuestionOptions: [...undoQuestionOptions, state.questionOptions],
        }
      })
    } else if (action === 'copy xml') {
      let dataUrl = `an/projects/${history.location.pathname.split('/')[2]}/analysis/${datasetId}/adoc`
      if (datasetType === 'surveys') {
        dataUrl = `an/projects/${history.location.pathname.split('/')[2]}/analysis/surveys/${datasetId}/adoc`
      }
      fetchCopyXML(dataUrl, token, returnUpdatedAnalysisBody(params.analysisType, 'topline', state.newQuestionOptions, state.firstColumn))
        .then(res => {
          if (res && (res.message || res.error)) {
            setErrorMessage(res.error ? res.error : res.message)
          } else {
            res.text().then(data => {
              returnBrowser() === 'Safari' ?
                setShowCopyXmlModal({ show: true, xmlCode: data })
                :
                navigator.clipboard.writeText(data).then(() => {
                  dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'XML definition has been copied to clipboard' } })
                });
            })
            setErrorMessage(null)
          }
        })
    }
    setDidMount(true)
  }

  const setLanguageError = (err) => {
    setErrorMessage(err)
  }

  return (
    <React.Fragment>
      {showOptionsModal &&
        <OptionsDialog
          tab='topline'
          activeGroup={optionsData.selectedGroup}
          data={optionsData.data}
          dropdownValues={optionsData.dropdownValues}
          weightValues={weightValues}
          values={state.questionOptions}
          updateValues={state.newQuestionOptions}
          expandedOptions={expandedOptions}
          optionGroups={optionsData.groups}
          handleClose={handleOptionsDialogClose}
          handleUpdate={handleOptionsUpdate}
          setCombineFilterData={setCombineFilterData}
          userData={userData}
          datasetId={datasetId}
          combineFilterData={combineData}
          user={user}
          token={token}
          analysisType={datasetType}
          optionId={state.optionId}
          initialOptionsData={state.initialOptionsData}
          initialAndUpdateOptionsValues={state.initialAndUpdateOptionsValues}
          onApplyWeightSet={(value) => onApplyWeightSet(value, state)}
        />
      }
      {
        showExportModal?.show &&
        <ExportModal
          onHide={() => { setShowExportModal({ ...showExportModal, show: false }); dispatch({ type: 'CLOSE_EXPORT_MODAL' }) }}
          showExportModal={showExportModal}
          options={{ exportOptions: optionsData.exportOptions, values: optionsData.dropdownValues }}
          onExportHandler={returnActionsData}
          state={state}
          updatedLanguage={editingLanguage}
          projectType={params.analysisType}
          datasetId={datasetId}
          history={history}
          tab={'topline'}
          datasetName={datasetName}
          datasetType={datasetType}
          token={token} />
      }
      {
        showWeightWizard &&
        <WeightWizard
          handleClose={() => setShowWeightWizard(false)}
          token={token}
          datasetId={datasetId}
          user={user} />
      }
      {showCopyXmlModal.show && <CopyXmlModal xmlCode={showCopyXmlModal.xmlCode} onHide={() => setShowCopyXmlModal({ show: false, xmlCode: '' })} />}
      <div className={"d-flex flex-column overflow-hidden flex-fill"}>
        <div className={"justify-content-between flex-wrap d-flex border-left pr-1"}>
          <div className="btn-group m-2 analysis-actions" role="group">
            <Button
              className={"btn btn-outline-analyze px-2 d-flex"}
              disabled={checkedRows.length > 0 ? false : true}
              onClick={onAddToReport}>Add to report
            </Button>

            <div className="btn-group" role="group">
              <DropDownButton
                buttonClass={"btn btn-outline-analyze rounded-0 d-flex flex-row-reverse px-2"}
                className='analyze-actions-button'
                icon="fas fa fa-caret-down"
                text="Clear"
                textField="actionName"
                items={clearQuestionItems}
                onItemClick={(props) => clearQuestions(props)}
                itemRender={(props) => (
                  <div className="p-1 dropdown-button__clear--small">
                    <span>
                      {props.item.actionName}
                    </span>
                  </div>
                )}
              />
            </div>

            <div className="btn-group" role="group">
              <DropDownButton
                text={"Actions"}
                textField="actionName"
                icon="fas fa fa-caret-down"
                className="analyze-actions-button"
                popupSettings={{ popupClass: 'actions-dropdown' }}
                buttonClass="btn btn-outline-analyze d-flex flex-row-reverse rounded-0 px-2"
                items={actionItems.filter(e => !e.actionName.includes("Columns"))}
                onItemClick={(e) => onActionHandler(e.item.action)}
              />
            </div>

            <Button
              className="btn btn-outline-analyze px-2"
              onClick={onOptionsClick}
              title="Options">Options
            </Button>
            {languages.length > 1 ?
              <div className='btn-group' role='group'>
                <LanguageSwitchButton
                  languages={languages}
                  editingLanguage={editingLanguage}
                  defaultLanguage={defaultLanguage}
                  tabType={"topline"}
                  newQuestionOptions={state.newQuestionOptions}
                  firstColumn={state.firstColumn}
                  setLanguageError={setLanguageError}
                  token={token}
                  requestUrl={datasetType === "surveys" ? `an/projects/${projectId}/analysis/surveys/${datasetId}` : `an/projects/${projectId}/analysis/${datasetId}`}
                  dispatchType={'TOPLINE_SET_TABLE_STATE'}
                  dataType={updateToplineData}
                  analysisBodyType={"topline"}
                  componentType={"topline"}
                />
              </div> : null}
          </div>

          <div className="btn-group m-2 analysis-actions" role="group">
            <ZoomButtons
              userSettings={userSettings}
              zoomType={'topLineZoom'}
            />
            <DropdownButton
              hideChevron={true}
              className='btn-outline-analyze strong px-2'
              items={updatedItems}
              renderItem={actionItemRender}
              renderMainButton={() => (
                <span style={{ fontFamily: "Walr Inter", fontSize: "13px", fontWeight: 600 }} className='user-info'>Export</span>
              )}
            />
            <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
              <button
                type='button'
                className="btn btn-outline-analyze btn-ic p-1"
                disabled={state?.firstColumn?.length === 0}
                title="Fullscreen"
                onClick={() => dispatch({ type: 'SET_SHOW_FULLSCREEN_RESULT', payload: true })}>
                <Icon type="expand" />
              </button>
            </Tooltip>
          </div>
        </div>
        <div className="w-100 d-flex overflow-hidden flex-fill border-table-area">
          <div className="w-100 d-flex topline">
            <div className='d-flex flex-column'>
              <DroppableColumn onCheck={onCheckQuestion} firstColumn={true} state={state} onSelectQuestion={onSelectQuestion} userData={userData} tab="topline" user={user} rangeItems={rangeItems} />
            </div>
            <div className="w-100 h-100 d-flex flex-column overflow-hidden bg-white" style={{ fontSize: `${((zoomLevel) * 0.875).toString()}rem` }}>
              {
                showFullscreenResult &&
                <ExpandResultModal
                  result={state.displayTable}
                  onHide={() => dispatch({ type: 'SET_SHOW_FULLSCREEN_RESULT', payload: false })}
                />
              }
              {
                !errorMessage ?
                  state.updateTable && state.firstColumn.length !== 0 ?
                    <InProgressOverlay theme="primary" type="fullscreen" />
                    :
                    <JsxParser jsx={state.displayTable} />
                  :
                  <ErrorMessage type="alert" errorMessage={errorMessage} />
              }
            </div>
          </div>
        </div >
      </div >
    </React.Fragment >
  )
}