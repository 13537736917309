import { useEffect, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { BaseDropDownList } from "../../../../../../../shared/Inputs";
import { ErrorMessage } from "../../../../../../../shared/ErrorMessage/ErrorMessage";
import type { CustomQuestion } from "../../../../../../../../interfaces/analysisInterfaces/analysisInterfaces"
import { fetchGetJson } from "../../../../../../../../services/services"

interface Props {
  submitImportedCreatedQuestion: (selectedQuestion: CustomQuestion[] | null) => void,
  hideModal: () => void;
  token: string;
}

export const ImportCreatedQuestionsModal = ({ submitImportedCreatedQuestion, hideModal, token }: Props) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [projects, setProjects] = useState<{ name: string }[]>([]);
  const [dataSets, setDataSets] = useState([]);
  const [createdQuestions, setCreatedQuestions] = useState<CustomQuestion[] | TODO[]>([]);
  const [selectedData, setSelectedData] = useState<{ selectedProjectId: { id: string } | null, selectedDataSetId: TODO | null, selectedCreatedQuestion: CustomQuestion[] | null, }>({ selectedProjectId: null, selectedDataSetId: null, selectedCreatedQuestion: null })

  // Function executes at start, fetches all projects available 
  useEffect(() => {
    fetchGetJson("projects", token)
      .then((res: TODO) => {
        if (res && (res.message || res.error)) {
          setErrorMessage(res.message ? res.message : res.error);
        } else {
          setProjects(res) // Populate "projects" state and the first dropdown list
        }
      })
  }, [token])

  const selectValueHandler = (event: TODO, type: string) => {
    switch (type) {
      case "projects":
        setSelectedData({ selectedProjectId: event.value, selectedDataSetId: null, selectedCreatedQuestion: null })
        //@ts-ignore
        fetchGetJson(`projects/${event.value.id}/datasets`, token)
          .then((res: TODO) => {
            if (res && (res.message || res.error)) {
              setErrorMessage(res.message ? res.message : res.error);
            } else {
              setDataSets(res) // Populate "dataSets" state and the second dropdown list
              setCreatedQuestions([]) // Reset "createdQuestions" state and the third dropdown list
            }
          })
        break
      case "dataSet":
        setSelectedData({ ...selectedData, selectedDataSetId: event.value, selectedCreatedQuestion: null })
        //@ts-ignore
        fetchGetJson(`an/projects/${selectedData.selectedProjectId.id}/analysis/${event.value.id}/custom-questions`, token)
          .then((res: TODO) => {
            if (res && (res.message || res.error)) {
              setErrorMessage(res.message ? res.message : res.error);
            } else {
              setCreatedQuestions(res) // Populate "createdQuestions" state and the third dropdown list
            }
          })
        break
      case "createdQuestions":
        setSelectedData({ ...selectedData, selectedCreatedQuestion: event.value })
        break
      default:
        return null;
    }
  }

  const noDataRender = (_e: TODO, dropDownType: string) => {
    return (
      <h5 className="text-muted text-center">
        <span className="k-icon k-i-warning my-3" style={{ fontSize: '2.5em' }} />
        <br />
        <span>{dropDownType === "projects" ? "No projects found" : dropDownType === "createdQuestions" ? "No custom questions found in selected dataset" : "No datasets found in selected project"}</span>
      </h5>
    )
  }

  return (
    <Dialog onClose={hideModal} title="Import Created Questions" className="import-created-questions" minWidth={600}>
      <div className="p-3" style={{ maxWidth: "602px" }}>
        <form className="d-flex flex-column">
          <div className="pb-5 w-100">
            <BaseDropDownList
              label="Projects"
              data={projects.sort((a, b) => a.name.localeCompare(b.name))}
              onChange={(e: TODO) => selectValueHandler(e, "projects")}
              textField="name"
              dataItemKey="id"
              listNoDataRender={(e: TODO) => noDataRender(e, "projects")}
              value={selectedData.selectedProjectId}
              required={true}
              style={{ width: '100%' }}
              className="w-100" />
          </div>
          <div className="pb-5 w-100">
            <BaseDropDownList
              label="Data Set"
              data={dataSets}
              onChange={(e: TODO) => selectValueHandler(e, "dataSet")}
              textField="name"
              dataItemKey="id"
              listNoDataRender={(e: TODO) => noDataRender(e, "dataSet")}
              value={selectedData.selectedDataSetId}
              required={true}
              style={{ width: '100%' }}
              className="w-100" />
          </div>
          <div className="pb-5 w-100">
            <MultiSelect
              label="Created Questions"
              data={createdQuestions}
              onChange={(e: TODO) => selectValueHandler(e, "createdQuestions")}
              textField="id"
              dataItemKey="id"
              listNoDataRender={(e: TODO) => noDataRender(e, "createdQuestions")}
              value={selectedData.selectedCreatedQuestion !== null ? selectedData.selectedCreatedQuestion : []}
              required={true}
              style={{ width: '100%' }}
              className="w-100" />
          </div>
        </form>
        {errorMessage &&
          <ErrorMessage
            type="alert"
            errorMessage={errorMessage}
            onHide={() => setErrorMessage("")}
          />
        }
      </div>
      <DialogActionsBar>
        <button type="button" className="k-button k-button-solid-base" onClick={hideModal}>Cancel</button>
        <button
          type="button"
          className="k-button btn btn-primary"
          disabled={!selectedData.selectedProjectId || !selectedData.selectedDataSetId || !selectedData.selectedCreatedQuestion}
          onClick={() => { hideModal(); submitImportedCreatedQuestion(selectedData.selectedCreatedQuestion) }}
        >Add Question</button>
      </DialogActionsBar>
    </Dialog >
  );
}